// extracted by mini-css-extract-plugin
export var awardCard = "cc_n1";
export var awardCard__bdg_large = "cc_n5";
export var awardCard__bdg_small = "cc_n6";
export var awardCard__container = "cc_pc";
export var awardCard__description = "cc_pb";
export var awardCard__info = "cc_n7";
export var awardCard__large = "cc_n3";
export var awardCard__link = "cc_n9";
export var awardCard__post = "cc_n2";
export var awardCard__small = "cc_n4";
export var awardCard__title = "cc_n8";